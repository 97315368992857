<template>
	<div class="section">
		<h3 class="heading-3-b mb-20">서비스 약관 동의</h3>
		<div class="contents policy-card">
			<div class="">
				<div class="group column vertical-form basic">
					<div class="group row border-bottom">
						<div class="group-chk" @click="reverseAgreeAll">
							<input type="checkbox" v-model="agreeAll" />
							<label class="label">서비스 약관 동의 (전체)</label>
						</div>
					</div>
					<div class="group column gap8">
						<div
							class="group row"
							v-for="(item, index) in termsModels"
							:key="index"
							v-if="!free_approved_linkplus(item)"
						>
							<div class="group-chk">
								<input
									type="checkbox"
									:checked="item.approved || free_approved_linkplus(item)"
									@click="
										item.approved = !item.approved;
										checkAllApproved();
									"
								/>
								<label
									class="label"
									@click="
										item.approved = !item.approved;
										checkAllApproved();
									"
									><span v-if="item.customerPortal" class="badge">{{ item.customerPortal }}</span>
									{{ item.termsName }}
									<template>{{ item.required ? '(필수)' : '(선택)' }}</template>
								</label>
								<a class="text-link" v-on:click="openTermsLayer(item)">내용보기</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import termsTemplate from '@/components/apply/TermsDetail.vue';
import { estimateTermsData, TERMS_MODAL_STYLE } from '@/utils/constants';
import { getTermDetail } from '@/api/customer';
export default {
	props: {
		type: {
			type: String,
		},
		saveFlag: {
			type: Boolean,
			default: false,
		},
		isFree: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			termsModels: [],
			agreeAll: false,
			openLayer: false,
			openTerms: {},
		};
	},
	created() {
		this.setTerms();
	},
	watch: {
		saveFlag() {
			if (this.saveFlag) {
				this.$emit('setTermsAgreement', this.termsModels);
			}
		},
		agreeAll() {
			if (this.agreeAll) {
				this.termsModels.forEach((obj) => {
					obj.approved = this.agreeAll;
				});
			}
		},
		termsModels: {
			deep: true,
			handler() {
				this.checkAllApproved();
			},
		},
	},
	computed: {
		free_approved_linkplus(_item) {
			return (_item) => {
				return _item.termsType === 'TERMS_OF_LINKPLUS' && this.isFree;
			};
		},
	},
	methods: {
		reverseAgreeAll() {
			this.agreeAll = !this.agreeAll;
			if (this.termsModels.length == 0) {
				return;
			}
			this.termsModels.forEach((obj) => {
				if (!this.free_approved_linkplus(obj)) {
					obj.approved = this.agreeAll;
				}
			});
		},
		checkAllApproved() {
			const filter = this.termsModels.filter((obj) => !obj.approved);
			this.agreeAll = filter.length == 0 ? true : false;
		},
		async setTerms() {
			let customerTermsOfUse = this.$store.getters.getCustomerTermsOfUse;
			customerTermsOfUse.approved = false;
			customerTermsOfUse.customerPortal = '고객포털';
			customerTermsOfUse.termsDetails = [];

			this.termsModels.push(customerTermsOfUse);
			if (this.type == 'ESTIMATE') {
				estimateTermsData.forEach((item) => this.termsModels.push(Object.assign(item, { approved: false })));
				this.termsModels = await this.cloneObj(this.termsModels);
			} else {
				const baseItem = this.$store.getters.getBasicItemSelected[0];
				if (baseItem.termsSectionModel.useTerms) {
					if (baseItem.termsSectionModel.termsModels.length > 0) {
						await baseItem.termsSectionModel.termsModels.forEach((obj) => {
							if (!obj.useForm) {
								return;
							}
							obj['approved'] = false;
							if (this.free_approved_linkplus(obj)) {
								obj.approved = true;
							}
							this.termsModels.push(obj);
						});
					}
				}
				this.termsModels = await this.cloneObj(this.termsModels);
			}
		},
		async openTermsLayer(item) {
			if (item.customerPortal && item.termsDetails.length === 0) {
				const resp = await getTermDetail(item.termsId);
				const detail = { bodyTemplate: resp.data?.termsContent };
				item.termsDetails.push(detail);
			}

			this.openTerms = item;
			this.$store.commit('INIT_AGREE_TERM');
			this.$modal.show(
				termsTemplate,
				{
					info: item,
				},
				Object.assign({}, TERMS_MODAL_STYLE, { name: 'termsTemplate', clickToClose: false }, { draggable: true }),
				{
					close: this.closeTermsLayer,
				},
			);
		},
		async closeTermsLayer() {
			const agreeTerm = this.$store.getters.getAgreeTerm;
			const temp = await this.cloneObj(this.termsModels);
			if (agreeTerm) {
				const idx = temp.findIndex((term) => term.id == this.openTerms.id);
				temp[idx].approved = true;
			}
			this.termsModels = temp;
			this.checkAllApproved();
		},
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
	},
};
</script>
