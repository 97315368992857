<template>
	<div class="group column gap20">
		<div class="section membership">
			<!--@멤버십-->
			<div class="card" v-if="this.$store.getters.getBasicItemSelected.length > 0">
				<div class="membership-card-header">
					<h3 class="title">
						{{ this.$store.getters.getBasicItemSelected[0].serviceCategoryModel.name }}
					</h3>
				</div>
				<div class="contents">
					<ul class="badge-group summary gap8">
						<li>
							<span class="badge line normal">
								<span class="txt">사용자{{ this.$store.getters.getBasicItemSelected[0].userCnt }}인</span>
							</span>
						</li>
						<li>
							<span class="badge line normal">
								<span class="txt">{{ this.$store.getters.getBasicItemSelected[0].usePeriod }}개월 </span>
							</span>
						</li>
					</ul>
					<div class="additional">
						<ul>
							<li>
								<span class="">Membership</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!--@결제금액-->
		<div class="section">
			<table class="membership-table">
				<thead>
					<tr>
						<th class="svc"><span class="txt">서비스명</span></th>
						<th class="item"><span class="txt">상품명</span></th>
						<th class="num"><span class="txt">수량</span></th>
						<th class="period"><span class="txt">사용기간</span></th>
						<th class="sum"><span class="txt">결제금액(원)</span></th>
					</tr>
				</thead>
				<tbody>
					<template v-for="service in tableData">
						<template v-for="(item, index) in service">
							<tr v-if="item.type == 'main'" :key="item.serviceName + '_' + item.itemName + '_main'">
								<td class="svc" v-if="index == 0" :rowspan="service.length">
									<span v-if="item.serviceName.indexOf('경리회계') > -1" class="ic-24 ic-accounting"></span>
									<span v-if="item.serviceName == '경비처리(무증빙)'" class="ic-svc ic-bizplay"></span>
									<span v-if="item.serviceName == 'TMSe 장비일체형 S'" class="ic-24 ic-tmse-s"></span>
									<span v-if="item.serviceName == 'TMSe 장비일체형 E'" class="ic-24 ic-tmse-e"></span>
									<span v-if="item.serviceName == 'TMSe 장비일체형 T'" class="ic-24 ic-tmse-t"></span>
									<span class="txt">{{ item.serviceName }}</span>

									<span class="com_tag" v-if="item.freeTrialPolicyModel && item.freeTrialPolicyModel.usePolicy">
										<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료체험</span>
									</span>
								</td>
								<td class="item">
									<span class="txt">{{ item.itemName }}</span>
								</td>
								<td class="num">
									<span class="txt"> {{ item.count }}</span>
								</td>
								<td class="period">
									<span class="txt">{{ item.usePeriod }}</span>
								</td>
								<td class="sum">
									<span v-if="item.finalPrice < item.originPrice" class="txt line-through mr-8"
										>{{ item.originPrice | currency }}원</span
									>
									<span class="txt">{{ item.finalPrice | currency }}원</span>
								</td>
							</tr>
							<tr
								v-if="item.type == 'sub'"
								class="sub"
								:key="index + '_' + item.serviceName + '_' + item.itemName + '_sub'"
							>
								<!--@sub data-->
								<td class="item">
									<i class="ic-16 ic-depth"></i>
									<span class="txt">{{ item.itemName }}</span>
								</td>
								<td class="num">{{ item.count }}</td>
								<td class="period"></td>
								<td class="sum">
									<span class="txt">{{ item.finalPrice | currency }}</span>
								</td>
							</tr>
						</template>
					</template>
				</tbody>

				<tfoot class="total">
					<tr>
						<td class="svc">
							<span class="txt">상품 금액</span>
						</td>
						<td class="sum" colspan="4">
							<span class="txt">{{ this.$store.getters.getSummuryPrices.totOriginPrice | currency }}원</span>
						</td>
					</tr>
					<tr class="">
						<td class="svc">
							<span class="txt">할인 금액</span>
						</td>
						<td class="sum discount" colspan="4">
							<span class="txt">{{ this.$store.getters.getSummuryPrices.totDiscountPrice | currency }}원</span>
						</td>
					</tr>
					<tr>
						<td class="svc">
							<span class="txt">상품 금액 - 할인 금액</span>
						</td>
						<td class="sum" colspan="4">
							<span class="txt">{{ this.$store.getters.getSummuryPrices.totPayPrice | currency }}원</span>
						</td>
					</tr>
					<tr>
						<td class="svc">
							<span class="txt">부가세(10%)</span>
						</td>
						<td class="sum" colspan="4">
							<span class="txt">{{ this.$store.getters.getSummuryPrices.surtaxPrice | currency }}원</span>
						</td>
					</tr>
					<tr>
						<td class="svc">
							<span class="txt">결제 금액(VAT포함)</span>
						</td>
						<td class="sum" colspan="4">
							<span class="txt"
								><em>{{ this.$store.getters.getSummuryPrices.finalPayPrice | currency }}</em
								>원</span
							>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tableData: [],
		};
	},
	async created() {
		this.tableData = await this.setTable();
	},
	methods: {
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
		async setTable() {
			var table = [];
			let services = [];
			const basicItemList = await this.$store.getters.getBasicItemSelected;
			const addonItemList = await this.$store.getters.getAddonItemSelected;
			await basicItemList.reduce((previous, baseChannel) => {
				return previous.then(async () => {
					await services.push(baseChannel);
				});
			}, Promise.resolve());

			await addonItemList.reduce((previous, addonChannel) => {
				return previous.then(async () => {
					await services.push(addonChannel);
				});
			}, Promise.resolve());

			let endDate;
			await services.reduce((previous, service) => {
				endDate = this.$store.getters.getCurrentChargeType === 'FREE' ? '만료일 없음' : service.usingDate.endDate;
				return previous.then(async () => {
					let serviceTable = [];
					if (service.defaultFeeTotDiscountPrice !== undefined && service.defaultFeePolicyModel.usePolicy) {
						let row = [];
						row.serviceName = service.name;
						row.itemName = '기본료';
						if (service.freeTrialPolicyModel && service.freeTrialPolicyModel.usePolicy) {
							row.count = service.freeTrialPolicyModel.usagePeriod + '개월 ';
						} else {
							row.count = service.usePeriod + '개월 ';
						}
						row.freeTrialPolicyModel = service.freeTrialPolicyModel;
						row.usePeriod = service.usingDate.startDate + '~' + endDate;
						row.finalPrice = service.defaultFeeTotDiscountPrice;
						row.originPrice = service.defaultFeeTotNormalPrice;
						row.type = 'main';
						await serviceTable.push(row);
					}

					if (service.addUserTotDiscountPrice !== undefined && service.addUserIntervalPolicyModel.usePolicy) {
						let row = [];
						row.serviceName = service.name;
						row.itemName = '사용자 추가';
						row.freeTrialPolicyModel = service.freeTrialPolicyModel;
						row.count = service.addUserCnt + '명';
						row.usePeriod = service.usingDate.startDate + '~' + endDate;
						row.finalPrice = service.addUserTotDiscountPrice;
						row.originPrice = service.addUserTotNormalPrice;
						row.type = 'main';
						await serviceTable.push(row);

						if (service.addUserDiscountMatrix) {
							await service.addUserDiscountMatrix.reduce((previousObj, obj) => {
								return previousObj.then(async () => {
									let subRow = {};
									subRow.serviceName = service.name;
									subRow.itemName = obj.interval + '명 (구간)';
									subRow.count = obj.discountCnt + '명';
									subRow.usePeriod = '';
									subRow.finalPrice = obj.totPrice + '원';
									subRow.originPrice = obj.totPrice + '원';
									subRow.type = 'sub';
									serviceTable.push(subRow);
								});
							}, Promise.resolve());
						}
					}
					if (service.membershipFeeDiscountPrice !== undefined && service.membershipFeePolicyModel.usePolicy) {
						let row = [];
						row.serviceName = service.name;
						row.itemName = '설치비';
						row.freeTrialPolicyModel = service.freeTrialPolicyModel;
						row.count = '1회';
						row.usePeriod = service.usingDate.startDate + '~' + endDate;
						row.finalPrice = service.membershipFeeDiscountPrice;
						row.originPrice = service.membershipFeeNormalPrice;
						row.type = 'main';
						await serviceTable.push(row);
					}

					if (service.optionItemSelected && service.optionItemSelected.length > 0) {
						await service.optionItemSelected.reduce((previousOption, currentOption) => {
							return previousOption.then(async () => {
								let row = [];
								row.serviceName = currentOption.name;
								row.itemName = currentOption.displaySectionModel.displayName;
								row.freeTrialPolicyModel = currentOption.freeTrialPolicyModel;
								row.count = currentOption.orderCount + '개';
								row.usePeriod = service.usingDate.startDate + '~' + endDate;
								row.finalPrice = currentOption.finalPrice;
								row.originPrice = currentOption.finalPrice;
								row.type = 'main';
								await serviceTable.push(row);
							});
						}, Promise.resolve());
					}

					table.push(serviceTable);
				});
			}, Promise.resolve());
			this.tableData = table;
			return table;
		},
		isAutoExtensionFreeTrial(service) {
			if (
				service.freeTrialPolicyModel.usePolicy &&
				service.usingCount > 0 &&
				this.$store.getters.getUsingMembership.autoExtension
			) {
				return true;
			}

			return false;
		},
	},
};
</script>

<style></style>
