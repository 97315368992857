<template>
	<div>
		<div class="header">
			<h1 class="heading-3-b">{{ info.termsName }}</h1>
			<button class="icon" @click="close()">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>
		<!--content-->
		<div class="contents-wrap">
			<div class="layer_terms">
				<div class="wrap_scroll">
					<p v-html="info.termsDetails[0].bodyTemplate"></p>
				</div>
			</div>
		</div>
		<!--//content end-->
		<div class="btn-area group-btn">
			<button class="line" @click="agree">확인</button>
		</div>
	</div>
</template>

<script>
export default {
	props: ['info'],
	methods: {
		async agree() {
			await this.$store.commit('SET_AGREE_TERM', true);
			await this.close();
		},
		async close() {
			this.$emit('close');
		},
	},
};
</script>

<style>
.terms_box table {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 16px 0;
}
.terms_box table th {
	font-weight: bold;
	font-weight: 500;
	padding: 8px 16px;
	border: 1px solid #d8d8d8;
}
.terms_box table td {
	padding: 8px 16px;
	border: 1px solid #d8d8d8;
}
</style>
